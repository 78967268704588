import { api_consolidator_error_handler } from "./api_utils";

export async function lockImage(image_id) {
    try {
        await api_consolidator_error_handler({method: "POST", endpoint: `images/${image_id}/lock`, raise_error: true});
        return true;
    } catch (error) {
        return false;
    }
}

export async function unlockImage(image_id) {
    return await api_consolidator_error_handler({method: "POST", endpoint: `images/${image_id}/unlock`})
}

export async function getNextOutsourcedImage() {
    return await api_consolidator_error_handler({method: "GET", endpoint: `images/outsourced/next`})
}

export async function saveAnnotations(image_id, keypoint_slider, row_distance_slider, keypoint_annotations=null, row_annotations=null) {
    let params = {
        keypoint_slider: keypoint_slider,
        row_distance_slider: row_distance_slider,
    }

    let data = {
        keypoints: keypoint_annotations,
        rows: row_annotations,
    }

    return await api_consolidator_error_handler({method: "PATCH", endpoint: `images/${image_id}/annotations`, data: data, params: params});
}

export async function updateQAStatus(image_id, qa_type, status) {
    let data = {}
    if (qa_type === "ppa_qa_status") {
        data["ppa_qa_status"] = status
    } else if (qa_type === "row_qa_status") {
        data["row_qa_status"] = status
    } else if (qa_type === "gender_qa_status") {
        data["gender_qa_status"] = status
    } else {
        console.log("Invalid QA type")
    }
    return await api_consolidator_error_handler({method: "PATCH", endpoint: `images/${image_id}`, data: data});
}

export async function getImage(image_id, presigned_thumbnails = false, presigned_urls = false) {
    let params = {
        presigned_thumbnails: presigned_thumbnails,
        presigned_urls: presigned_urls
    }
    return await api_consolidator_error_handler({method: "GET", endpoint: `images/${image_id}`, params: params})
}


export async function getAnalytics() {
    return await api_consolidator_error_handler({method: "GET", endpoint: "analytics", paginated: true})
}

export async function patchAnalytic(
    analytic_id, 
    outsourced = null, 
    keypoint_slider_default = null, 
    row_distance_slider_default = null, 
    image_buffer_pct = null, 
    status = null,
    priority = null,
    rowfill = null,
) {
    let data = {
        outsourced: outsourced,
        keypoint_slider_default: keypoint_slider_default,
        row_distance_slider_default: row_distance_slider_default,
        image_buffer_pct: image_buffer_pct,
        status: status,
        priority: priority,
        rowfill: rowfill
    }

    return await api_consolidator_error_handler({method: "PATCH", endpoint: `analytics/${analytic_id}`, data: data})
}

export async function getAnalytic(analytic_id) {
    try {
        return await api_consolidator_error_handler({method: "GET", endpoint: `analytics/${analytic_id}`, raise_error: true})
    } catch (error) {
        let error_msg = error.message;
        if (error.response) {
            error_msg = error.response.data.detail.msg;
        }
        alert(`Error getting analytic id=${analytic_id}: ${error_msg}`);
        return null;
    }
}

export async function getAnalyticJson(analytic_id) {
    return await api_consolidator_error_handler({method: "GET", endpoint: `analytics/${analytic_id}/json`})
}

export async function exportAnalytic(analytic_id, upload_as_released, include_rejected) {
    let params = {
        upload_as_released: upload_as_released,
        include_rejected: include_rejected
    }

    try {
        await api_consolidator_error_handler(
            {method: "POST", endpoint: `analytics/${analytic_id}/deliverables`, params: params, raise_error: true}
        );
        alert("Export Started");
    } catch (error) {
        if (error.response) {
            alert("Failed to start export: " + error.response.data.detail);
            console.error(error.response);
        } else {
            alert("Failed to start export: " + error.message);
            console.error(error.message);
        }
    }
} 

export async function uploadAnalytic(json_s3_path) {
    try {
        await api_consolidator_error_handler(
            {method: "POST", endpoint: "analytics", params: {json_s3_path: json_s3_path}, raise_error: true}
        );
        alert("Uploading Analytic");
    } catch(error) {
        alert("Error Uploading Analytic");
        console.error(error);
    }
}

export async function getNextImage(analytic_id, qa_type) {
    let params = {qa_type: qa_type}
    return await api_consolidator_error_handler({method: "GET", endpoint: `analytics/${analytic_id}/next_annotate_image`, params: params})
}

export async function getImages(analytic_id, presigned_thumbnails=false) {
    let params = {presigned_thumbnails: presigned_thumbnails}
    return await api_consolidator_error_handler({method: "GET", endpoint: `analytics/${analytic_id}/images`, params: params, paginated: true})
}

export async function patchSubimage(image_id, subimage_idx, rejected) {
    let params = {rejected: rejected}
    return await api_consolidator_error_handler({method: 'PATCH', endpoint: `images/${image_id}/subimages/${subimage_idx}`, params: params})
}
