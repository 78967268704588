<template>
    <div>
        <Sidebar :analytic_id="analytic_id" :rowfill="analytic.rowfill" v-if="analytic"/>

        <div class="toolbar" v-if="images">
            <!-- Global Controls Section -->
            <div class="toolbar-controls">
                <button class="medium-icon-button" @click="saveChanges" :disabled="changes.length == 0 || isRefreshing">
                    <i class="fa fa-save"></i>
                </button>
                <button class="medium-icon-button" @click="discardChanges" :disabled="changes.length == 0 || isRefreshing">
                    <i class="fa fa-undo"></i>
                </button>
                <button class="medium-icon-button" @click="refreshPage" :disabled="isRefreshing || loadingThumbnails">
                    <i :class="['fa', 'fa-refresh', { 'spin': isRefreshing || loadingThumbnails}]"></i>
                </button>
            </div>
            <hr class="divider">

            <!-- Thumbnails Section -->
            <div class="toolbar-section">
                <span class="section-label">Thumbnails</span>
                <div class="icon-row">
                    <button class="small-icon-button" @click="toggleThumbnails" :disabled="isRefreshing || loadingThumbnails">
                        <i :class="showAllThumbnails ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
                    </button>
                    <button class="small-icon-button" @click="decreaseThumbnailSize">
                        <i class="fa fa-minus"></i>
                    </button>
                    <button class="small-icon-button" @click="increaseThumbnailSize">
                        <i class="fa fa-plus"></i>
                    </button>
                </div>
            </div>
            <hr class="divider">

            <div class="toolbar-section">
                <span class="total-count">Total: {{ images.length }} Image(s)</span> <!-- Total image count label -->
            </div>

            <!-- Rejected Section -->
            <div class="toolbar-section">
                <div class="label-icon-row">
                    <div class="eye-icon-container">
                        <button class="small-icon-button" @click="toggleRejectedImages">
                            <i :class="showRejectedImages ?  'fa fa-eye' : 'fa fa-eye-slash'"></i>
                        </button>
                    </div>
                    <div class="label-and-count">
                        <span class="section-label">Rejected</span>
                        <span class="image-count">{{ rejectedCount }} Image(s)</span>
                    </div>
                </div>
            </div>

            <!-- Completed Section -->
            <div class="toolbar-section">
                <div class="label-icon-row">
                    <div class="eye-icon-container">
                        <button class="small-icon-button" @click="toggleCompletedImages">
                            <i :class="showCompletedImages ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
                        </button>
                    </div>
                    <div class="label-and-count">
                        <span class="section-label">Completed</span>
                        <span class="image-count">{{ completedCount }} Image(s)</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="grid-page">
            <h1 class="progress-title">Progress</h1>

            <!-- Thumbnail grid -->
            <div v-if="images" class="thumbnail-grid">
                <div 
                    v-for="(image, index) in images" 
                    :key="index" 
                    class="thumbnail-item" 
                    @mouseover="hoveredImage = image" 
                    @mouseleave="hoveredImage = null"
                >
                    <div 
                        class="status-bar-container" 
                        :style="{ width: (thumbnailSize + 100) + 'px' }" 
                        :class="[getImageStatusClass(image), { 'disabled': isRefreshing }]"
                        v-if="image.showStatusBar"
                    >
                        <!-- Image Name with Toggle Thumbnail Button -->
                        <div class="image-name-toggle">
                            <div class="image-name">{{ image.name }}</div>
                            <button class="toggle-thumbnail" @click="toggleImageThumbnail(image)" :disabled="loadingThumbnails">
                                <span :class="image.showThumbnail ? 'arrow-down' : 'arrow-left'"></span>
                            </button>
                        </div>

                        <!-- Thumbnail container with RGB/Binary, Row, and Keypoints overlays -->
                        <div v-if="image.showThumbnail" class="thumbnail-container">
                            <!-- Switch between RGB and Binary Thumbnail -->
                            <img :src="image.showBinary ? image.binary_thumbnail_url : image.rgb_thumbnail_url" alt="Thumbnail" class="thumbnail-img" :style="{ width: thumbnailSize + 'px', height: 'auto' }"/>
                            <!-- Row Thumbnail (overlay) -->
                            <img v-if="image.showRows" :src="image.rows_thumbnail_url" alt="Row Thumbnail" class="row-thumbnail" :style="{ width: thumbnailSize + 'px', height: 'auto' }"/>
                            <!-- Keypoints Thumbnail (overlay) -->
                            <img v-if="image.showKeypoints" :src="image.keypoints_thumbnail_url" alt="Keypoints Thumbnail" class="keypoints-thumbnail" :style="{ width: thumbnailSize + 'px', height: 'auto' }"/>

                            <!-- 3x3 Grid Overlay for Subimages (Always on, buffered) -->
                            <div class="grid-overlay" :style="getBufferedStyle()">
                                <div v-for="(subimage, subIndex) in image.subimages" 
                                    :key="subIndex" 
                                    class="grid-cell"
                                    :class="{ 'changed-cell': isSubimageChanged(image, subIndex) }" 
                                    @click="toggleSubimageStatus(image, subIndex)"
                                >
                                    <!-- Display scalable 'X' if subimage is rejected -->
                                    <div v-if="subimage.rejected" class="grid-rejected"></div>
                                </div>
                            </div>
                        </div>

                        <!-- Status Bar -->
                        <div class="status-bar">
                            <!-- Lock Information: Lock Icon and Locked By Name -->
                            <div class="lock-info">
                                <button 
                                    @click="toggleLock(image)" 
                                    class="qa-status-button" 
                                    :class="[{ 'changed-qa': isLockChanged(image), 'locked': image.locked_by && !isLockChanged(image) }]"
                                    @mouseover="showLockedAtTooltip(image)" 
                                    @mouseleave="hideLockedAtTooltip(image)"
                                >
                                    {{ image.locked_by ? '\u{1F512}' : '\u{1F513}' }} <!-- Locked/Unlocked Icon -->
                                </button>
                                <span class="lock-status">{{ getLockedByName(image.locked_by) }}</span>

                                <!-- Tooltip to display user list -->
                                <div v-if="image.showLockedAt" class="user-list-tooltip">
                                    {{  formatToLocalTime(image.locked_at) }}
                                </div>
                            </div>

                            <!-- Row QA Status -->
                            <div class="qa-status-item" :class="getQALabelClass(image, 'row_qa_status')">
                                <span class="qa-status-label" @click.prevent="openQAInNewTab(image, RouteNames.RowQA)">Row QA</span>
                                <button 
                                    @click="toggleQAStatus(image, 'row_qa_status')" 
                                    class="qa-status-button"
                                    :class="{ 'changed-qa': isQAChanged(image, 'row_qa_status') }"
                                >
                                    {{ getQAStatusIcon(image.row_qa_status) }}
                                </button>
                            </div>

                            <!-- PPA QA Status -->
                            <div class="qa-status-item" :class="getQALabelClass(image, 'ppa_qa_status')">
                                <span 
                                    class="qa-status-label" 
                                    @click.prevent="!analytic.rowfill && openQAInNewTab(image, RouteNames.PlantCountQA)" 
                                    :class="{'disabled': analytic.rowfill}"
                                >
                                    PPA QA
                                </span>
                                <button 
                                    @click="toggleQAStatus(image, 'ppa_qa_status')" 
                                    class="qa-status-button" 
                                    :class="{ 'changed-qa': isQAChanged(image, 'ppa_qa_status') }"
                                >
                                    {{ getQAStatusIcon(image.ppa_qa_status) }}
                                </button>
                            </div>

                            <!-- Gender QA Status -->
                            <div class="qa-status-item" :class="getQALabelClass(image, 'gender_qa_status')">
                                <span class="qa-status-label" @click.prevent="openQAInNewTab(image, RouteNames.GenderQA)">Gender QA</span>
                                <button 
                                    @click="toggleQAStatus(image, 'gender_qa_status')" 
                                    class="qa-status-button"
                                    :class="{ 'changed-qa': isQAChanged(image, 'gender_qa_status') }"
                                >
                                    {{ getQAStatusIcon(image.gender_qa_status) }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                Loading...
            </div>
        </div>
    </div>
</template>

<script>
import { getAnalytic, getImages, patchSubimage, updateQAStatus, lockImage, unlockImage, getImage } from '../utils/api_endpoints.js';
import { get_key_from_route_params, navigateToRoute } from "../utils/nav_utils.js"; // Restored the import
import { get_username, trimText } from '../utils/qa_utils.js';
import Sidebar from './Sidebar.vue'; // Import the Sidebar component
import { RouteNames } from '../utils/constants.js';

export default {
    components: {
        Sidebar // Register Sidebar component here
    },
    computed: {
        rejectedCount() {
            return this.images.filter(image => image.ppa_qa_status === 'rejected' || 
                                                image.row_qa_status === 'rejected' || 
                                                image.gender_qa_status === 'rejected').length;
        },
        completedCount() {
            return this.images.filter(image => image.ppa_qa_status === 'completed' && 
                                                image.row_qa_status === 'completed' && 
                                                image.gender_qa_status === 'completed').length;
        }
    },
    data() {
        return {
            analytic: null,
            images: null,
            thumbnailSize: 700, // Default thumbnail size in pixels
            showAllThumbnails: false,
            showAllBinaries: false, 
            showAllRows: false,
            showAllKeypoints: false,
            isRefreshing: false,
            showRejectedImages: true, // Track visibility of rejected images
            showCompletedImages: true, // Track visibility of completed images
            loadingThumbnails: false,
            hoveredImage: null, // Track hovered image
            changes: [], // Centralized list of changes
            originalImages: {}, // Store original image states
            RouteNames: RouteNames,
        }
    },
    async created() {
        this.analytic_id = get_key_from_route_params(this, "analytic_id");
        let images = await getImages(this.analytic_id);
        this.analytic = await getAnalytic(this.analytic_id);

        // Initialize overlay visibility for each image
        images.forEach(image => {
            this.originalImages[image.id] = JSON.parse(JSON.stringify(image));

            this.$set(image, 'showThumbnail', false); 
            this.$set(image, 'showBinary', false); 
            this.$set(image, 'showRows', false);
            this.$set(image, 'showKeypoints', false); 
            this.$set(image, 'showKeypoints', false); 
            this.$set(image, 'showUsers', false); 
            this.$set(image, 'showKeypoints', false);
            this.$set(image, 'showUsers', false); 
            this.$set(image, 'showStatusBar', true); 
            this.$set(image, 'showLockedAt', false)
        });

        // Delay displaying image status bars until everything is loaded
        this.images = images;

        // Add event listeners for keybindings
        window.addEventListener('keydown', this.handleKeydown);
    },
    methods: {
        handleKeydown(event) {
            if (event.key === 'R') {
                this.toggleAllRowOverlays();
            } else if (event.key === 'r' && this.hoveredImage !== null) {
                this.toggleRowOverlay(this.hoveredImage);
            } else if (event.key === 'K') {
                this.toggleAllKeypointOverlays();
            } else if (event.key === 'k' && this.hoveredImage !== null) {
                this.toggleKeypointOverlay(this.hoveredImage);
            } else if (event.key === 'B') {
                this.toggleAllBinaryThumbnails();
            } else if (event.key === 'b' && this.hoveredImage !== null) {
                this.toggleBinaryThumbnail(this.hoveredImage);
            } else if (event.key === '-') {
                this.decreaseThumbnailSize()
            } else if (event.key === '=') {
                this.increaseThumbnailSize()
            } else if (event.key === 's' && event.ctrlKey) {
                event.preventDefault();
                this.saveChanges();
            }
        },
        toggleAllRowOverlays() {
            this.showAllRows = !this.showAllRows;
            this.images.forEach(image => {
                this.$set(image, 'showRows', this.showAllRows);
            });
        },
        toggleRowOverlay(image) {
            this.$set(image, 'showRows', !image.showRows);
        },
        toggleAllKeypointOverlays() {
            this.showAllKeypoints = !this.showAllKeypoints;
            this.images.forEach(image => {
                this.$set(image, 'showKeypoints', this.showAllKeypoints);
            });
        },
        toggleKeypointOverlay(image) {
            this.$set(image, 'showKeypoints', !image.showKeypoints);
        },
        toggleAllBinaryThumbnails() {
            this.showAllBinaries = !this.showAllBinaries;
            this.images.forEach(image => {
                this.$set(image, 'showBinary', this.showAllBinaries);
            });
        },
        toggleBinaryThumbnail(image) {
            this.$set(image, 'showBinary', !image.showBinary);
        },
        async toggleThumbnails() {
            this.showAllThumbnails = !this.showAllThumbnails;

            this.loadingThumbnails = true;
            
            const thumbnailPromises = this.images.map(image => this.toggleImageThumbnail(image, this.showAllThumbnails));
            await Promise.all(thumbnailPromises);

            this.loadingThumbnails = false;
        },
        async toggleImageThumbnail(image, showThumbnail = null) {
            if (showThumbnail === null) {
                showThumbnail = !image.showThumbnail;
            }

            if (showThumbnail && !image.rgb_thumbnail_url) {
                const updatedImage = await getImage(image.id, true);
                this.$set(image, 'rgb_thumbnail_url', updatedImage.rgb_thumbnail_url);
                this.$set(image, 'binary_thumbnail_url', updatedImage.binary_thumbnail_url);
                this.$set(image, 'rows_thumbnail_url', updatedImage.rows_thumbnail_url);
                this.$set(image, 'keypoints_thumbnail_url', updatedImage.keypoints_thumbnail_url);
            }

            this.$set(image, 'showThumbnail', showThumbnail);
        },
        increaseThumbnailSize() {
            if (this.thumbnailSize < window.innerWidth - 650) {
                this.thumbnailSize += 20;
            }
        },
        decreaseThumbnailSize() {
            if (this.thumbnailSize > 200) {
                this.thumbnailSize -= 20;
            }
        },
        trackChange(imageId, field, newValue, subimageIdx = null) {
            const originalValue = subimageIdx !== null 
                ? this.originalImages[imageId].subimages[subimageIdx][field]
                : this.originalImages[imageId][field];

            if (newValue !== originalValue) {
                const existingChange = this.changes.find(change => 
                    change.imageId === imageId && change.field === field && change.subimageIdx === subimageIdx);

                if (existingChange) {
                    existingChange.newValue = newValue;
                } else {
                    this.changes.push({ imageId, field, newValue, subimageIdx });
                }
            } else {
                // Remove the change if it reverts to the original state
                this.changes = this.changes.filter(change => 
                    !(change.imageId === imageId && change.field === field && change.subimageIdx === subimageIdx));
            }
        },
        toggleQAStatus(image, qaField) {
            // Cycle through 'not_done', 'completed', and 'rejected'
            if (image[qaField] === 'not_done') {
                image[qaField] = 'completed';
            } else if (image[qaField] === 'completed') {
                image[qaField] = 'rejected';
            } else {
                image[qaField] = 'not_done';
            }

            this.trackChange(image.id, qaField, image[qaField]);
        },
        toggleLock(image) {
            if (image.locked_by === null) {
                image.locked_by = get_username();
            } else {
                image.locked_by = null;
            }
            
            this.trackChange(image.id, 'locked_by', image.locked_by);
        },
        toggleSubimageStatus(image, subimageIndex) {
            const subimage = image.subimages[subimageIndex];
            const newStatus = !subimage.rejected;
            
            subimage.rejected = newStatus;
            this.trackChange(image.id, 'rejected', newStatus, subimageIndex);
        },
        toggleRejectedImages() {
            this.showRejectedImages = !this.showRejectedImages;

            this.images.forEach(image => {
                if (this.getImageStatusClass(image).includes('rejected-status-bar')) {
                    if (this.showRejectedImages) {
                        this.$set(image, "showStatusBar", true)
                    } else {
                        this.$set(image, "showStatusBar", false)
                    }
                    
                }
            })
        },
        toggleCompletedImages() {
            this.showCompletedImages = !this.showCompletedImages;

            this.images.forEach(image => {
                if (this.getImageStatusClass(image).includes('completed-status-bar')) {
                    if (this.showCompletedImages) {
                        this.$set(image, "showStatusBar", true)
                    } else {
                        this.$set(image, "showStatusBar", false)
                    }
                    
                }
            })
        },
        getBufferedStyle() {
            let buffer_pct = this.analytic?.image_buffer_pct || 0;
            let remaining_pct = 100 - (buffer_pct * 2);
            return {     
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)',
                gridTemplateRows: 'repeat(3, 1fr)',
                position: 'absolute',
                top: buffer_pct + "%",
                left: buffer_pct + "%",
                height: remaining_pct + "%",
                width: remaining_pct + "%",
                boxSizing: 'border-box',
                border: '2px solid rgba(255, 255, 255, 0.8)',
            }
        },
        async saveChanges() {
            this.isRefreshing = true;

            for (const change of this.changes) {
                if (change.field === 'locked_by') {
                    // Handle locking and unlocking separately
                    if (change.newValue) {
                        await lockImage(change.imageId); // Lock the image
                    } else {
                        await unlockImage(change.imageId); // Unlock the image
                    }
                } else if (change.subimageIdx !== null) {
                    await patchSubimage(change.imageId, change.subimageIdx, change.newValue)
                } else {
                    // Handle QA status and subimage status changes using updateQAStatus
                    await updateQAStatus(change.imageId, change.field, change.newValue);
                }
            }

            this.changes = [];
            this.images.forEach(image => {
                this.originalImages[image.id] = JSON.parse(JSON.stringify(image));
            });
            this.isRefreshing = false;
            
            // Trigger re-render
            this.$forceUpdate();
        },
        isLockChanged(image) {
            return image.locked_by !== this.originalImages[image.id].locked_by;
        },
        isQAChanged(image, qaField) {
            return image[qaField] !== this.originalImages[image.id][qaField];
        },
        isSubimageChanged(image, subimageIndex) {
            const originalStatus = this.originalImages[image.id].subimages[subimageIndex].rejected;
            return image.subimages[subimageIndex].rejected !== originalStatus;
        },
        getQAStatusIcon(status) {
            if (status === 'not_done') return '\u2753'; // Unicode Question Mark
            if (status === 'completed') return '\u2705'; // Unicode Green Check Mark
            if (status === 'rejected') return '\u274C'; // Unicode Red X
            return ''; // Default to no icon if status is invalid
        },
        getImageStatusClass(image) {
            const originalImage = this.originalImages[image.id];

            // Compare only QA status fields
            const hasUnsavedQAChanges = (
                image.ppa_qa_status !== originalImage.ppa_qa_status ||
                image.row_qa_status !== originalImage.row_qa_status ||
                image.gender_qa_status !== originalImage.gender_qa_status
            );

            if (hasUnsavedQAChanges) {
                return ''; // No status class if there are unsaved QA changes
            }

            // If no unsaved QA changes, return completed or rejected class based on the image's status
            const { ppa_qa_status, row_qa_status, gender_qa_status } = image;

            if (ppa_qa_status === 'rejected' || row_qa_status === 'rejected' || gender_qa_status === 'rejected') {
                return 'rejected-status-bar'; // Apply red background for rejected status
            }
            
            
            // If all statuses are completed, return the class for a green status bar

            // If all statuses are completed, return the class for a green status bar
            if ((ppa_qa_status === 'completed' || this.analytic.rowfill) && row_qa_status === 'completed' && gender_qa_status === 'completed') {
                return 'completed-status-bar'; // Apply green background for completed status
            }

            return ''; // No specific status class
        },
        getLockedByName(email) {
            if (!email) return '';
            return email.split('@')[0]; // Return the part before '@'
        },
        openQAInNewTab(image, route_name) {
            navigateToRoute(this, route_name, this.analytic_id, {image_id: image.id, "new_tab": true}, true)
        },
        showLockedAtTooltip(image) {
            if (image.locked_at && image.locked_by) {
                image.showLockedAt = true;
            }
        },
        hideLockedAtTooltip(image) {
            image.showLockedAt = false;
        },
        async refreshImage(image) {
            // Fetch the updated image data
            const updatedImage = await getImage(image.id, image.showThumbnail);

            for (const key in updatedImage) {
                if (Object.prototype.hasOwnProperty.call(updatedImage, key)) {
                    this.$set(image, key, updatedImage[key]);
                }
            }

            updatedImage.subimages.forEach((updatedSubimage, index) => {
                this.$set(image.subimages[index], 'rejected', updatedSubimage.rejected);
            });

            // Update the original image state to reflect the new values (including subimages)
            this.originalImages[image.id] = JSON.parse(JSON.stringify(updatedImage));
        },
        async refreshPage() {
            // Check if there are unsaved changes
            if (this.changes.length > 0) {
                const confirmDiscard = confirm('You have unsaved changes. Do you want to discard them?');
                if (!confirmDiscard) {
                    // User doesn't want to discard changes, don't refresh
                    return;
                } else {
                    // Clear changes if user confirms
                    this.changes = [];
                }
            }

            // Set global refreshing state
            this.isRefreshing = true;

            // Refresh all images in parallel
            const refreshPromises = this.images.map(image => this.refreshImage(image));
            await Promise.all(refreshPromises);

            // Turn off global refreshing state
            this.isRefreshing = false;

            // Trigger re-render
            this.$forceUpdate();
        },
        getQALabelClass(image, qa_status) {
            if (image[qa_status] === 'completed' && !this.getImageStatusClass(image)) {
                return 'qa-approved';
            } else if (image[qa_status] === 'rejected' && !this.getImageStatusClass(image)) {
                return 'qa-rejected';
            } else {
                return '';
            }
        },
        discardChanges() {
            this.images.forEach((image) => {
                const original = this.originalImages[image.id];

                image.ppa_qa_status = original.ppa_qa_status;
                image.row_qa_status = original.row_qa_status;
                image.gender_qa_status = original.gender_qa_status;

                image.subimages.forEach((subimage, idx) => {
                    subimage.rejected = original.subimages[idx].rejected;
                });

                image.locked_by = original.locked_by;
            });

            // Clear the changes array
            this.changes = [];
        },
        formatToLocalTime(datetime) {
        return new Date(datetime).toLocaleString();  // Converts to the local timezone
        }
    },
    beforeDestroy() {
        // Remove the keybinding event listener
        window.removeEventListener('keydown', this.handleKeydown);
    }
}
</script>

<style scoped>
.grid-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 95px;
    margin-left: 220px;
    margin-right: 250px;
}

.progress-title {
    margin-bottom: 20px;
    font-weight: bold;
    color: #333;
}

.thumbnail-grid {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Adjust spacing between thumbnails */
}

.thumbnail-img, .row-thumbnail, .keypoints-thumbnail {
    display: block;
    max-width: 100%; 
    height: auto; /* Preserve aspect ratio */
}

.row-thumbnail, .keypoints-thumbnail {
    position: absolute; /* Position the overlays on top of the RGB thumbnail */
    top: 0;
    left: 0;
    opacity: 0.8; /* Optional: You can adjust opacity if needed */
}

.grid-cell {
    position: relative;
    box-sizing: border-box;
    border: 2px solid rgba(255, 255, 255, 0.8); /* Optional: Grid cell borders */
}

.grid-rejected {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background: transparent;
    display: block;
}

.grid-rejected:before, .grid-rejected:after {
    position: absolute;
    content: '';
    background: red;
    display: block;
    width: 80%;
    height: 15%;
    top: 50%;
    left: 50%;
    transform-origin: center center;
    transform: translate(-50%, -50%) rotate(-45deg);
}

.grid-rejected:after {
    transform: translate(-50%, -50%) rotate(45deg);
}

.resize-controls {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.resize-controls button {
    margin: 0 10px;
    padding: 5px 10px;
    font-size: 16px;
    cursor: pointer;
}

.changed-cell {
    background-color: rgba(255, 255, 0, 0.5); /* Highlight unsaved changes */
}

/* Container for the status bar and image name */
.status-bar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f9f9f9;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    min-width: 800px;
    margin: 0 auto;
}

/* Disable status bar container when refreshing */
.status-bar-container.disabled {
    opacity: 0.6;
    pointer-events: none;
}

.image-name-toggle {
    display: flex;
    justify-content: center; /* Center align the content */
    width: 100%;
    align-items: center;
    position: relative;
}

.image-name {
    font-size: 20px;
    font-weight: 600;
    color: #333;
    text-align: center; /* Ensures the text is centered */
    flex-grow: 1; /* Ensures the name takes up available space */
}

.toggle-thumbnail {
    background: none;
    border: none;
    cursor: pointer;
    color: #007bff;
    position: relative;
    right: 0px;
}

.arrow-down, .arrow-left {
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
}

.arrow-down {
    border-width: 12px 6px 0 6px; /* Creates a downward-pointing arrow */
    border-color: #444444 transparent transparent transparent;
}

.arrow-left {
    border-width: 6px 12px 6px 0; /* Creates a leftward-pointing arrow */
    border-color: transparent #444444 transparent transparent;
}

/* Status Bar Grid Layout */
.status-bar {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Four equal sections for lock and QA statuses */
    width: 100%;
    align-items: center;
    margin-top: 10px;
    padding: 10px;
}

/* Thumbnail Item */
.thumbnail-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto; /* Center the thumbnail items */
}

.thumbnail-container {
    display: flex;
    justify-content: center;
    background-color: #f9f9f9; /* Ensure it matches the status bar */
    position: relative; /* Ensure that the grid-overlay is positioned correctly relative to this container */
}

/* Lock Information Styling */
.lock-info {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.lock-status {
    margin-left: 10px;
    font-weight: 500;
    max-width: 130px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; 
}

/* QA Status Item Styling */
.qa-status-item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
}

/* QA Status Button Styling */
.qa-status-button {
    padding: 6px 12px;
    border: 1px solid rgba(0, 0, 0, 0.2); /* No border by default */
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.2s ease, color 0.2s ease, border-color 0.2s ease;
    background: none;
    text-align: center;
}

/* Hover effect: subtle change to make it clear it's clickable */
.qa-status-button:hover {
    background-color: rgba(0, 0, 0, 0.1); /* Light darkening of the background */
}

.qa-status-label {
    padding: 6px;
    border: 1px solid transparent; /* No border by default */
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
    color: #666;
    font-weight: bold;
    margin-right: 6px;
}

/* Hover effect: subtle change to make it clear it's clickable */
.qa-status-label:hover:not(.disabled) {
    background-color: rgba(0, 0, 0, 0.1); /* Light darkening of the background */
    border-color: rgba(0, 0, 0, 0.2); /* Add a subtle border on hover */
}

.qa-status-label.disabled {
    color: #ccc; /* Grayed out color */
    cursor: not-allowed; /* Change the cursor to indicate it's disabled */
    opacity: 0.6; /* Dim the element to indicate it's disabled */
}

/* Adding vertical dividers between grid items */
.status-bar > div:not(:last-child) {
    border-right: 1px solid #ddd;
}

/* Status bar container green background for completed status */
.completed-status-bar {
    background-color: rgba(0, 255, 0, 0.2); /* Light green background */
}

/* Status bar container red background for rejected status */
.rejected-status-bar {
    background-color: rgba(255, 0, 0, 0.2); /* Light red background */
}

/* Style for the changed QA button */
.qa-status-button.changed-qa {
    background-color: yellow;
}

.qa-status-button.locked {
    background-color: red;
}

/* Style for the approved (completed) QA status */
.qa-approved {
    background-color: rgba(0, 255, 0, 0.2); /* Light green background */
    border-radius: 8px; /* Optional: Add rounded corners */
}

.qa-rejected {
    background-color: rgba(255, 0, 0, 0.2);
    border-radius: 8px; /* Optional: Add rounded corners */
}

.user-list-tooltip {
    position: absolute; /* Position relative to the lock button */
    bottom: 150%; /* Place the tooltip above the button */
    left: 50%;
    transform: translateX(-50%); /* Center the tooltip horizontally */
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    white-space: nowrap;
    z-index: 1000;
}

.user-list-tooltip::after {
    content: "";
    position: absolute;
    top: 100%; /* Arrow pointing downwards, below the tooltip */
    left: 50%;
    transform: translateX(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: #f9f9f9 transparent transparent transparent; /* Tooltip arrow */
}

/* Controls Sidebar */

.toolbar {
    position: fixed;
    top: 50%; /* Center the toolbar vertically */
    right: 30px; /* Keep the toolbar pinned to the right side of the page */
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 220px;
    gap: 15px;
    background-color: #f9f9f9;
}

.toolbar-controls {
    display: flex;
    justify-content: center;
    gap: 15px; /* Adjusted space between top buttons */
}

.medium-icon-button {
    background-color: transparent;
    color: black;
    border: 1px solid gray;
    padding: 10px 12px;
    border-radius: 4px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.medium-icon-button:disabled {
    color: gray;
    cursor: not-allowed;
}

.medium-icon-button:hover:not(:disabled) {
    background-color: #f0f0f0;
}

.small-icon-button {
    background-color: transparent;
    color: black;
    border: 1px solid gray;
    padding: 6px 8px; /* Smaller button size for eye, minus, and plus */
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px; /* Smaller icon size */
    transition: background-color 0.3s, color 0.3s;
}

.small-icon-button:disabled {
    color: gray;
    cursor: not-allowed;
}

.small-icon-button:hover:not(:disabled) {
    background-color: #f0f0f0;
}

.divider {
    width: 100%;
    border: 1px solid #ddd;
    margin: 10px 0;
}

.toolbar-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.label-icon-row {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px; /* Space between the icon and the label */
    width: 100%;
}

.eye-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px; /* Controls the width of the eye icon column */
    height: 48px; /* Ensures consistent height for eye icons */
}

.label-and-count {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers the labels and counts vertically */
    width: 100px; /* Ensures both labels have the same width */
}

.icon-row {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 8px;
}

.section-label {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    text-align: center; /* Centers the labels */
}

.image-count {
    font-size: 14px;
    color: #666;
    text-align: center; /* Centers the count directly below the label */
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.spin {
    animation: spin 1s linear infinite;
}

.total-count {
    font-size: 15px;
    color: #666;
    text-align: center;
}
</style>
